/* You can add global styles to this file, and also import other style files */

/* Layout */
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "primeng/resources/primeng.min.css";
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";

body {
  background: var(--surface-section) !important;
}

.content-container {
  max-width: 1500px;
  margin: 0 auto;
}
